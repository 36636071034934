*{
    font-family: 'Alegreya',sans-serif;
}

body{
    background-color: lightslategray;
}
.main{
    max-width: 1400px;
    margin: auto;
} 
.navbar{
    background-color: rgb(79, 84, 89);
    color: red;
}
.imgbox { 
    margin-top: -8px;
    /*position: absolute; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;  */
    /* src: url('./assets/images/image1.jpg'); */
    position: relative;
    color: #05386b;
    
    /* z-index: -1  */
}
div.img span{
    position: absolute;
    top:30%;
    left:15%;
    font-size: 1.5em;
}

div.shippercarrier{
    margin-top: -10px;
    padding-top: 40px;
    height:200px;
}
 /* .cont{
    height: 100px;
    margin:0 ;
}  */

Col{
    position: relative;

}
.shipperimg{
    margin-top: -20%;
}

.revealfromleft{
    position: relative;
    transform: translateX(-150px);
    opacity: 0;
    transition: all 2s ease;
}
.revealfromleft.active{
    transform: translateX(0px);
    opacity: 1;
} 

.revealfromright{
    position: relative;
    transform: translateX(150px);
    opacity: 0;
    transition: all 2s ease;
}
.revealfromright.active{
    transform: translateX(0px);
    opacity: 1;
}

.revealfromtop{
    position: relative;
    transform: translateY(-150px);
    opacity: 0;
    transition: all 2s ease;
}
.revealfromtop.active{
    transform: translateY(0px);
    opacity: 1;
}

.revealfrombottom{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: all 2s ease;
}
.revealfrombottom.active{
    transform: translateY(0px);
    opacity: 1;
}
.content{
    height:100vh;
    
}
.text-content{
    position: absolute;
    top:50%;
    left:50%;
    padding: 5px 15px;

}
header {
    background: rgb(8, 8, 8);
}
.header2{
    background: rgb(147, 131, 131);
}

.mainimage {
   object-fit: cover;
   opacity: 0.4;
}
.parent {
    font-family: 'poppins',sans-serif !important;
    position: relative;
    text-align: center;
    color: rgb(232, 159, 41);
}
.centered {
    text-align: center;
    position: absolute;
    top: 30%;
    left: 22%; 
}
@media screen and (min-width: 300px){
    .centered {
        text-align: center;
        position: absolute;
        top: 30%;
        left: 5%; 
    }

    
}
@media screen and (min-width: 600px){
    .centered {
        text-align: center;
        position: absolute;
        top: 30%;
        left: 18%; 
    }

    

}
@media screen and (min-width: 900px){
    .centered {
        text-align: center;
        position: absolute;
        top: 30%;
        left: 24%; 
    }

    
}

@media screen and (min-width: 1200px){
    .centered {
        text-align: center;
        position: absolute;
        top: 30%;
        left: 27%; 
    }
}
@media screen and (min-width: 1300px){
    .centered {
        text-align: center;
        position: absolute;
        top: 30%;
        left: 30%; 
    }

    
}
